import Masonry from 'masonry-layout/dist/masonry.pkgd.min';
import imagesLoaded from 'imagesloaded';
import './index.sass';

let masonry;

const initMasonry = () => {
  imagesLoaded('.js-grid-item', () => {
    masonry = new Masonry('.js-grid-container', {
      itemSelector : '.js-grid-item',
    });
  });
}

if($('.js-grid-container').length) {
  initMasonry();
}

const removeOldItems = (items) => {
  masonry.remove(items);
}

const loadNew = ($illustrations) => {
  imagesLoaded($illustrations, () => {
    $illustrations && masonry.appended($illustrations);
    masonry.layout();
    $illustrations.removeClass('grid-hidden');
    $('.list-loader').remove();
  });
}

export {
  loadNew,
  removeOldItems
}
