import Swiper from 'swiper/swiper-bundle.min';
import 'swiper/swiper-bundle.min.css';
import './index.sass';

export default () => {
  if($('.home-slider__slide').length === 1) {
    $('.home-slider__next, .home-slider__prev').addClass('home-slider__nav--hidden');
  }
  if($('.home-slider__slide').length > 1) {
    const mySwiper = new Swiper('.home-slider__container', {
      loop: true,
      navigation: {
        nextEl: '.home-slider__next',
        prevEl: '.home-slider__prev',
      }
    });
  }
}