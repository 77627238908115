import throttle from "lodash-es/throttle";
import Swiper from 'swiper/swiper-bundle.min';
import 'swiper/swiper-bundle.min.css';
import './index.sass';

/*
 * КОПИПАСТА С HOME-BEST
 * Понимаю, что реализация оставляет желать лучшего, но это самый быстрый способ
 * Все вопросы по вменяемости написанного тут переадресовываю автору блока home-best
 * */
export default () => {
  const $root = $('.js-home-authors-slider');

  if ($root.length) {
    let swipers = []; // Если слайдеров несколько - обязательно объявляем массив
    const initSwiper = () => {
      const screenWidth = $(window).width();
      if(screenWidth > 640 && swipers.length == 0) { // проверяем, что массив пуст
        $root.each(function () {
          const $this = $(this);
          const slider = new Swiper($this.find('[data-role="container"]').get(0), {
            loop: false,
            slidesPerView: 3,
            slidesPerGroup: 4,
            spaceBetween: 10,
            breakpoints: {
              992: {
                slidesPerView: 4.3,
                spaceBetween: 24
              },
            },
            navigation: {
              nextEl: $this.find('[data-role="next"]').get(0),
              prevEl: $this.find('[data-role="prev"]').get(0),
            },
          });
          swipers.push(slider); // накапливаем слайдеры в массиве
        })
      } else if (screenWidth < 640 && swipers.length > 0) { // проверяем, что массив не пуст
        swipers.forEach(function (swiper) { // проходимся по слайдерам и вызываем дестрой на каждом инстансе с праметрами очистки стилей
          swiper.destroy(true, true);
        })
        swipers = []; // обнуляем массив
      }
    }
    initSwiper();

    $(window).on('resize', throttle(() => initSwiper(), 150));
  }
};
