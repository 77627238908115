import initHome from 'pages/home';
import 'pages/dialog_members/dialog-slider/index.sass';
import initTabs from 'blocks/tabs';
import initCardControls from 'blocks/card-controls';
import 'blocks/banner';
import 'blocks/user-work';
import 'blocks/pulse-card';

import { handleLikeCard } from 'blocks/card';
import initAccountConfirmationPopup from "blocks/account-confirmation-popup";

$(() => {
    initHome();

    initTabs($('.tabs'));
    handleLikeCard();
    initCardControls();

    $('#account-confirmation-popup').length && initAccountConfirmationPopup()
})
