import './index.sass';
import axios from 'axios';

export default () => {
  const $form = $('.home-subscription__form');

  $form.on('submit', (e) => {
    e.preventDefault();
    axios({
      method: 'POST',
      url: $form.attr('action'),
      data: $form.serialize(),
    }).then(response => {
      $form.trigger('reset');
      showMessage(response.data.message);
    })
  })

}