import './index.sass';

export default () => {
    $('.js-remove-agree').on('click', e => {
        e.preventDefault();
        $(e.currentTarget).parents('.card').find('.card-agree').toggleClass('card-agree--show');
    });

    $('.js-remove-job-agree').on('click', e => {
        e.preventDefault();
        $(e.currentTarget).parents('.job-card').find('.card-agree').toggleClass('card-agree--show');
    });

    $('.js-remove-cancel').on('click', e => {
        e.preventDefault();
        $(e.currentTarget).parents('.card-agree').removeClass('card-agree--show');
    });

    $('.js-agree-current-remove').on('click', e => {
        e.preventDefault();
        $('.card-agree').addClass('card-agree--show');
    });

    // remove
    $(document).on('click', '.js-remove-button', function(e) {
        e.preventDefault();
        $.ajax({
            url: $(this).data('url'),
            method: 'POST',
            headers: {"X-CSRF-Token": $('meta[name=csrf-token]').attr('content')},
            data: {
                _method: 'delete'
            }
        }).done(function(data) {
            return window.location = data.location;
        });
    });

    // add to favourite
    $(document).on('click', '.js-favorite-remove-button', function(e) {
        e.preventDefault();
        var $this;
        $this = $(this);
        $.ajax({
            url: $(this).data('url'),
            method: 'POST',
            headers: {"X-CSRF-Token": $('meta[name=csrf-token]').attr('content')},
            data: {
                _method: 'delete'
            },
            error: function(jqXHR, textStatus, errorThrown) {
                return alert(textStatus);
            },
            success: function(data, textStatus, jqXHR) {
                if($this.hasClass('card-controls__btn')) {
                    $this.addClass('card-controls__btn--hidden');
                    $this.closest('.card-controls').find('.card-controls__btn--favorite').removeClass('card-controls__btn--hidden');
                }
                var $icon;
                $icon = $this.closest('.js-remove-popup-container').find('.controls .js-favorite-remove-button');
                $icon.removeClass('js-favorite-remove-button button-favorite-active');
                $icon.addClass('js-favorite-button');
            }
        });
        $(this).closest('.js-favorite-popup').hide();
    });
    $(document).on('click', '.js-favorite-button', function(e) {
        console.log(2);
        e.preventDefault();
        var $this;
        $this = $(this);
        $.ajax({
            url: $(this).data('url'),
            method: 'POST',
            headers: {"X-CSRF-Token": $('meta[name=csrf-token]').attr('content')},
            data: {
                _method: 'post'
            },
            error: function(jqXHR, textStatus, errorThrown) {
                return alert(textStatus);
            },
            success: function(data, textStatus, jqXHR) {
                if($this.hasClass('card-controls__btn')) {
                    $this.addClass('card-controls__btn--hidden');
                    $this.closest('.card-controls').find('.card-controls__btn--favorite-active').removeClass('card-controls__btn--hidden');
                }
                var $icon;
                $icon = $this.closest('.js-remove-popup-container').find('.controls .js-favorite-button');
                $icon.removeClass('js-favorite-button');
                return $icon.addClass('js-favorite-remove-button button-favorite-active');
            }
        });
        $(this).closest('.js-favorite-popup').hide();
        return false;
    });

    //best product
    $(document).on('click', '.js-bestproduct-remove-button', function() {
        var $this;
        $this = $(this);
        $.ajax({
            url: $(this).data('url'),
            method: 'POST',
            headers: {"X-CSRF-Token": $('meta[name=csrf-token]').attr('content')},
            data: {
                _method: 'delete'
            },
            error: function(jqXHR, textStatus, errorThrown) {
                return alert(textStatus);
            },
            success: function(data, textStatus, jqXHR) {
                if($this.hasClass('card-controls__btn')) {
                    $this.addClass('card-controls__btn--hidden');
                    $this.closest('.card-controls').find('.card-controls__btn--best').removeClass('card-controls__btn--hidden');
                }
                var $icon;
                $icon = $this.closest('.js-remove-popup-container').find('.controls .js-bestproduct-remove-button');
                $icon.removeClass('js-bestproduct-remove-button button-bestproduct-active');
                return $icon.addClass('js-bestproduct-button');
            }
        });
        $(this).closest('.js-bestproduct-popup').hide();
        return false;
    });

    $(document).on('click', '.js-bestproduct-button', function() {
        var $this;
        $this = $(this);
        $.ajax({
            url: $(this).data('url'),
            method: 'POST',
            headers: {"X-CSRF-Token": $('meta[name=csrf-token]').attr('content')},
            data: {
                _method: 'post'
            },
            error: function(jqXHR, textStatus, errorThrown) {
                return alert(textStatus);
            },
            success: function(data, textStatus, jqXHR) {
                if($this.hasClass('card-controls__btn')) {
                    $this.addClass('card-controls__btn--hidden');
                    $this.closest('.card-controls').find('.card-controls__btn--best-active').removeClass('card-controls__btn--hidden');
                }
                var $icon;
                $icon = $this.closest('.js-remove-popup-container').find('.controls .js-bestproduct-button');
                $icon.removeClass('js-bestproduct-button');
                return $icon.addClass('js-bestproduct-remove-button button-bestproduct-active');
            }
        });
        $(this).closest('.js-bestproduct-popup').hide();
        return false;
    });
}
