import 'magnific-popup/dist/jquery.magnific-popup.min'
import initHomeSlider from './home-slider';
import initHomeCards from './home-cards';
import initHomeBest from './home-best';
import initHomeSubscription from './home-subscription';
import initHomeAuthors from './home-authors';
import initHomePulse from './home-pulse';
import './home-jobs/index.sass';
import './index.sass';
import Cookies from "js-cookie";

export default () => {
  initHomeSlider();
  initHomeCards();
  initHomeBest();
  initHomeSubscription();
  initHomeAuthors();
  initHomePulse();

  const initAccountConfirmationPopup = () => {
    $.magnificPopup.open( {
      items: {
        src: '#account-confirmation-popup'
      },
      mainClass: 'mfp-account-confirmation',
      type: 'inline',
      closeBtnInside: true,
      closeOnBgClick: true,
      callbacks: {
        close: function () {
          Cookies.set('account_confirmation_popup_shown', true);
        }
      }
    });
  }

  $('#account-confirmation-popup').length && initAccountConfirmationPopup()
}
