import './index.sass';
import { loadNew, removeOldItems } from '~/blocks/grid';

export default ($root) => {
  $root.find('.js-tabs-filter').on('click', function () {
    $(this).toggleClass('tabs__filter--open')
  });
  if ($root.find('.js-select-multiple').length) {
    // $root.find('.js-select-multiple').on("select2:select select2:unselect", function (e) {
      // if(!$(this).closest('form').find('.filter-hidden').length) {
      //   $(this).closest('form').append('<input class="filter-hidden" type="hidden" name="' + $(this).attr('name') + '" value="' + e.params.data.id + '">');
      // }
    // });
    // $root.find('.js-select-multiple').on("select2:unselect", function (e) {
    //   $('.filter-hidden[value="' + e.params.data.id + '"]').remove();
    // });
    $root.find('.js-submit-filters').on("click", function () {
      $(this).closest('form').submit();
    });
    $root.find('#sort_by').on("change", function () {
      $(this).closest('form').submit();
    });
  }

  const $tabs = $('.js-search-tabs');
  if($tabs.length) {
    const $input = $tabs.find('input');
    $tabs.find('.tab').on('click', e => {
      $input.val($(e.currentTarget).data('value'))
    })
  }

  const $tabsSort = $('.js-tab-sort');

  if($tabsSort.length) {
    $tabsSort.on('click', function() {
      const $that = $(this);
      const $items = $('.js-grid-item');
      if($that.hasClass('tab--active')) {
        $that.removeClass('tab--active');
        $("#badge").val();
        $tabsSort.removeClass('tab--active');
      } else {
        $("#badge").val($that.attr('drop-value'));
        $tabsSort.removeClass('tab--active');
        $that.addClass('tab--active');
      }
      removeOldItems($items);
      appendImages();
    })
  }

  const appendImages = () => {
    $(document).off().on('ajax:success', (event) => {
      const resp = event.detail[0];
      const $products = $(resp.items);
      $products.addClass('grid-hidden');
      $('.js-search-count').text(event.detail[0].total_entries)
      $('.js-grid-container').append($products);
      loadNew($products);
    });
  }
}
