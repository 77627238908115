import './index.sass';
import axios from 'axios';

const handleLikeCard = () => {
  $('.js-card-like').on('click', e => {
    e.stopPropagation();
    e.preventDefault();
    const $target = $(e.currentTarget);
    const $countLike = $target.parents('.card').find('.js-like-count');
    const url = $target.attr('data-url');
    const method = $target.attr('data-method');

    axios({
      method,
      headers: {
        'Accept': 'application/json',
        "X-CSRF-Token": $('meta[name=csrf-token]').attr('content')
      },
      url: url,
    }).then(response => {
      if(response.data.flag === true) {
        $target.addClass('card__follow--check');
        $countLike.html(response.data.count_likes);
        $target.attr('data-url', response.data.url);
        $target.attr('data-method', response.data.method);
      } else {
        $target.removeClass('card__follow--check');
        $countLike.html(response.data.count_likes);
        $target.attr('data-url', response.data.url);
        $target.attr('data-method', response.data.method);
      }
    })
  })
}

export {
  handleLikeCard
}